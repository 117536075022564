var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"d-flex justify-content-center pt-3"},[(_vm.messageSend === true)?_c('b-card',{staticClass:"col-lg-8"},[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('titles.contacts_us')))]),_c('div',{staticClass:"alert alert-success p-2"},[_c('p',[_vm._v(_vm._s(_vm.$t('messages.your_message_was_sent_thank_you')))])])],1):_vm._e(),(_vm.messageSend === false)?_c('b-card',{staticClass:"col-lg-8"},[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('titles.contacts_us')))]),_c('validation-observer',{ref:"contactForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{attrs:{"model":_vm.page},on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.your_names'),"prop":"names"}},[_c('validation-provider',{attrs:{"name":"names","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('labels.your_names')},model:{value:(_vm.page.names),callback:function ($$v) {_vm.$set(_vm.page, "names", $$v)},expression:"page.names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.your_email'),"prop":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","placeholder":"example@mail.com","state":errors.length > 0 ? false:null},model:{value:(_vm.page.email),callback:function ($$v) {_vm.$set(_vm.page, "email", $$v)},expression:"page.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('labels.message'),"prop":"message"}},[_c('validation-provider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":5,"placeholder":_vm.$t('placeholders.what_do_you_want_to_share_with_us'),"state":errors.length > 0 ? false:null},model:{value:(_vm.page.message),callback:function ($$v) {_vm.$set(_vm.page, "message", $$v)},expression:"page.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":"6LckdKMdAAAAAPH06v2ssZxpHj9KAIJFFf8QLkqa"},on:{"verify":_vm.verifyMethod}})],1),_c('b-button',{attrs:{"variant":"primary","disabled":invalid || !_vm.captchaVerifiedKey || _vm.formLoading,"loading":_vm.formLoading},on:{"click":_vm.sendMessage}},[_vm._v(" "+_vm._s(_vm.$t('buttons.send_message'))+" ")])],1)]}}],null,false,2878571276)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }