<template>
  <b-container class="d-flex justify-content-center pt-3">
    <b-card class="col-lg-8" v-if="messageSend === true">
      <b-card-title>{{ $t('titles.contacts_us') }}</b-card-title>
      <div class="alert alert-success p-2">
        <p>{{ $t('messages.your_message_was_sent_thank_you') }}</p>
      </div>
    </b-card>
    <b-card class="col-lg-8" v-if="messageSend === false">
      <b-card-title>{{ $t('titles.contacts_us') }}</b-card-title>
      <validation-observer
          ref="contactForm"
          #default="{invalid}"
      >
        <b-form :model="page" @submit.prevent="sendMessage">
          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('labels.your_names')" prop="names">
                <validation-provider
                    #default="{ errors }"
                    name="names"
                    rules="required"
                >
                  <b-form-input v-model="page.names"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="$t('labels.your_names')"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('labels.your_email')" prop="email">
                <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                >
                  <b-form-input type="email" v-model="page.email"
                                placeholder="example@mail.com"
                                :state="errors.length > 0 ? false:null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group :label="$t('labels.message')" prop="message">
            <validation-provider
                #default="{ errors }"
                name="message"
                rules="required"
            >
              <b-form-textarea v-model="page.message" :rows="5"
                               :placeholder="$t('placeholders.what_do_you_want_to_share_with_us')"
                               :state="errors.length > 0 ? false:null"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <vue-recaptcha sitekey="6LckdKMdAAAAAPH06v2ssZxpHj9KAIJFFf8QLkqa" @verify="verifyMethod" ref="recaptcha"></vue-recaptcha>
          </b-form-group>
          <b-button variant="primary" @click="sendMessage"
                    :disabled="invalid || !captchaVerifiedKey || formLoading"
                    :loading="formLoading">
            {{ $t('buttons.send_message') }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </b-container>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BContainer,
  BCard,
  BCardTitle,
  BRow,
  BCol
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: {
    BForm, BFormInput, BFormGroup, BFormTextarea, BButton, BContainer, BCard, BCardTitle, BRow, BCol,
    ValidationProvider, ValidationObserver, VueRecaptcha
  },
  name: "Contacts",
  metaInfo(){
    return {
      title: this.query || this.$t('page_titles.contacts')
    }
  },
  data() {
    return {
      page: {
        names: null,
        email: null,
        message: null
      },
      formLoading: false,
      messageSend: false,
      required, email,
      captchaVerifiedKey: null
    }
  },
  methods: {
    sendMessage() {
      this.formLoading = true;
      this.$http.post('/contacts', this.page).then(() => {
        this.messageSend = true;
        this.$notify().success(this.$t('messages.your_message_was_sent_thank_you'));
      }).catch(error => {
        this.$notify().error(error.message);
      }).finally(() => {
        this.formLoading = false;
      })
    },
    verifyMethod(v){
      this.captchaVerifiedKey = v
    }
  }
}
</script>
